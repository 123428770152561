import React, { useState } from 'react';
import { Field, useFormikContext } from "formik";
import { TIPOVI_POREZA } from '../../../constants/racuni';
import { jediniceMjereService } from '../../../services/JediniceMjereService';
import DropDown from '../../shared/forms/DropDown';
import DropDownStatic from '../../shared/forms/DropDownStatic';

const BezgotovinskiNoveCijeneStavkiForm = ({ azurirajStavku, setAzurirajStavku, authPreduzece, odabranaStavka }) => {
    const { values, setFieldValue } = useFormikContext();
    const [ lastClickedInput, setLastClickedInput ] = useState('');

    const setManualValues = (values, event, type) => {
        if (! values.novi_porez) {
            setDefaultPdv();
        }

        if (type === 'bez_pdv') {
            setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, +event.target.value);
            setFieldValue(`niz_stavke[0].nova_ukupna_cijena`, +event.target.value * (1 + +values.novi_porez));
            setFieldValue(`niz_stavke[0].novi_pdv`, +event.target.value * +values.novi_porez);

            setFieldValue(`niz_stavke[0].marza`, (+event.target.value - +values.nabavna_cijena) / +values.nabavna_cijena * 100);
        }

        if (type === 'sa_pdv') {
            setFieldValue(`niz_stavke[0].nova_ukupna_cijena`, +event.target.value);
            setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, +event.target.value / (1 + +values.novi_porez));
            setFieldValue(`niz_stavke[0].novi_pdv`, +event.target.value / (1 + +values.novi_porez) * +values.novi_porez);
        }
    };

    const setManualPdv = (values, option) => {
        setFieldValue(`niz_stavke[0].novi_porez_id`, option.id);
        setFieldValue(`niz_stavke[0].novi_porez`, option.value);
        setFieldValue(`niz_stavke[0].novi_porez_naziv`, option.label);

        if (lastClickedInput === 'bez_pdv') {
            if (values?.nova_cijena_bez_pdv) {
                setFieldValue(`niz_stavke[0].nova_ukupna_cijena`, values.nova_cijena_bez_pdv * (1 + +option.value));
            }
        }

        if (lastClickedInput === 'sa_pdv') {
            if (values?.nova_ukupna_cijena) {
                setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, values.nova_ukupna_cijena / (1 + +option.value));
            }
        }
    };

    function izracunajMarzu(value) {
        let nova_cijena_bez_pdv = +values.niz_stavke[0].nabavna_cijena * (+value / 100 + 1);

        setFieldValue(`niz_stavke[0].marza`, value);
        setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, nova_cijena_bez_pdv);

        if (! values.niz_stavke[0].novi_porez) {
            setDefaultPdv();
        }

        setFieldValue(`niz_stavke[0].nova_ukupna_cijena`, nova_cijena_bez_pdv * (1 + +values.niz_stavke[0].novi_porez));
        setFieldValue(`niz_stavke[0].novi_pdv`, nova_cijena_bez_pdv * +values.niz_stavke[0].novi_porez);
        setLastClickedInput('bez_pdv');
    }

    function setDefaultPdv() {
        if (authPreduzece.pdv_obveznik) {
            setFieldValue(`niz_stavke[0].novi_porez`, 0.21);
            setFieldValue(`niz_stavke[0].novi_porez_id`, 4);
            setFieldValue(`niz_stavke[0].novi_porez_naziv`, '21%');
        } else {
            setFieldValue(`niz_stavke[0].novi_porez`, 0.00);
            setFieldValue(`niz_stavke[0].novi_porez_id`, 1);
            setFieldValue(`niz_stavke[0].novi_porez_naziv`, 'Oslobodjen PDV');
        }
    }

    return (
        <>
            <div className="main-content__box--body mb-20 mt-4">
                <div className="container">
                    <div className="section-box">
                        <div className="section-box__left">
                            <div className="section-box__left--top">
                                <div className="form__group">
                                    <label htmlFor="" className="form__label bm-show">
                                        Nabavna cijena
                                    </label>
                                    <Field
                                        type="number"
                                        name={`niz_stavke[0].nabavna_cijena`}
                                        className="form__input"
                                        value={values?.niz_stavke[0]?.nabavna_cijena ?? ''}
                                        placeholder="Nabavna cijena"
                                    />
                                </div>
                                <div className="form__group">
                                    <label htmlFor="" className="form__label bm-show">
                                        Nova ukupna cijena
                                    </label>
                                    {/* TODO: izgaseno edit Cijena dok se ne rijesi slanje izmijenjene cijene */}
                                    <Field
                                        name={`niz_stavke[0].nova_ukupna_cijena`}
                                        type="number"
                                        value={values.niz_stavke[0]?.nova_ukupna_cijena ?? ''}
                                        className="form__input"
                                        placeholder="Nova ukupna cijena"
                                        onChange={(event) => {
                                            setLastClickedInput('sa_pdv')
                                            setManualValues(values.niz_stavke[0], event, 'sa_pdv')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section-box__right">
                            <div className="section-box__right--top-wrap">
                                <div className="el">
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Zavisni trosak
                                        </label>
                                        <Field
                                            type="number"
                                            name={`niz_stavke[0].zavisni_troskovi`}
                                            className="form__input"
                                            value={values?.niz_stavke[0]?.zavisni_troskovi ?? ''}
                                            placeholder="Zavisni trosak"
                                            onBlur={(value) => setFieldValue(`niz_stavke[0].nabavna_cijena`, +values.niz_stavke[0].nabavna_cijena + +value.target.value)}
                                        />
                                    </div>
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Stopa PDV-a
                                        </label>
                                        <label htmlFor="stavke.0.jedinica_mjere_id" className="form__label" />
                                        <DropDownStatic
                                            name={`niz_stavke[0].novi_porez`}
                                            options={TIPOVI_POREZA}
                                            onChangeExtra={(option) => {
                                                setManualPdv(values.niz_stavke[0], option)
                                            }}
                                            defaultValue={
                                                {
                                                    value: authPreduzece.pdv_obveznik ? 0.21 : 0.00,
                                                    label: authPreduzece.pdv_obveznik ? '21%' : 'Oslobodjen PDV'
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="el">
                                    <div className="form__group mb-4">
                                        <label htmlFor="" className="form__label bm-show">
                                            Marza
                                        </label>
                                        <Field
                                            type="number"
                                            name={`niz_stavke[0].marza`}
                                            className="form__input"
                                            value={values?.niz_stavke[0]?.marza ?? ''}
                                            placeholder="Marza"
                                            onChange={(event) => izracunajMarzu(event.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="el">
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            Nova cijena bez pdv
                                        </label>
                                        <Field
                                            type="number"
                                            name={`niz_stavke[0].nova_cijena_bez_pdv`}
                                            className="form__input"
                                            value={values?.niz_stavke[0]?.nova_cijena_bez_pdv ?? ''}
                                            placeholder="Nova cijena bez PDV"
                                            onChange={(event) => {
                                                setLastClickedInput('bez_pdv')
                                                setManualValues(values.niz_stavke[0], event, 'bez_pdv')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="el">
                                    <div className="form__group">
                                        <label htmlFor="" className="form__label bm-show">
                                            PDV
                                        </label>
                                        <Field
                                            name={`niz_stavke[0].novi_pdv`}
                                            type="number"
                                            value={values.niz_stavke[0]?.novi_pdv ?? ''}
                                            className="form__input"
                                            placeholder="PDV"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                id="azuriraj"
                                name="azuriraj"
                                onChange={() => setAzurirajStavku(! azurirajStavku)}
                            />
                            <label htmlFor="azuriraj" className="ml-2">
                                {! odabranaStavka ? 'Sacuvaj stavku  u listu stavki' : 'Postavi novu cijenu stavke u listi stavki'}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BezgotovinskiNoveCijeneStavkiForm;
